/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';
import {
  Checkbox, FormControlLabel, Grid, Link,
} from '@mui/material';
import { Field } from 'react-final-form';
import { GeneralContext } from '../../../util/Store';

export default function Toc() {
  const generalStore = useContext(GeneralContext);
  const onClick = (e) => {
    e.preventDefault();
    generalStore.setOpenPrivacyPolicy(true);
  };

  return (
    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
      <Field
        name="agb"
        type="checkbox"
        render={({ input }) => (
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox />}
              required
              label={(
                <span>
                  Ich habe die
                  {' '}
                  <Link onClick={onClick}>Datenschutzerklärung</Link>
                  {' '}
                  gelesen und akzeptiere sie.
                </span>
              )}
              sx={{ marginTop: '7px', marginLeft: 0 }}
              {...input}
            />
          </Grid>
        )}
      />
      {/* <Field
        name="wiederruf"
        type="checkbox"
        render={({ input }) => (
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox />}
              required
              label="Die Widerrufsinformationen habe ich zur Kenntnis genommen."
              sx={{ marginTop: '7px', marginLeft: 0 }}
              {...input}
            />
          </Grid>
        )}
      /> */}
    </Grid>
  );
}
