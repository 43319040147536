import React from 'react';
import { Button, Grid } from '@mui/material';

export default function StepButtons({
  currentIndex, onBackClick, elements, currentElement,
}) {
  // if (currentElement.type === 'attendanceConfirmation') return null;

  return (
    <Grid sx={{ pb: 2, pt: 6, textAlign: 'right' }}>
      {currentIndex !== 0 ? (
        <Button
          variant="outlined"
          onClick={onBackClick}
          sx={{ marginRight: 2 }}
        >
          Zurück
        </Button>
      ) : undefined}

      {currentIndex === (elements.length - 1) && currentElement.type !== 'switchForm' ? (
        <Button variant="contained" type="submit">
          Absenden
        </Button>
      ) : (<Button variant="contained" type="submit">Weiter</Button>)}
    </Grid>
  );
}
