/* eslint-disable import/prefer-default-export */
export const mapSeatingIntoAttendees = (data, steps) => {
  const tableSeatingPlanStep = steps.find(({ type }) => type === 'tableSeatingPlan');
  const useSeatingPlan = tableSeatingPlanStep && (!tableSeatingPlanStep?.params?.minSeats
    || (tableSeatingPlanStep?.params?.minSeats && tableSeatingPlanStep?.params?.minSeats <= data.attendees.length + 1));

  if (data.seating && useSeatingPlan) {
    data.attendees.forEach((attendee) => { delete attendee.seat; delete attendee.seatTable; });
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const [tableId, seats] of Object.entries(data.seating)) {
      for (const [index, seatAttendeeId] of Object.entries(seats)) {
        if (seatAttendeeId === undefined) continue;
        if (seatAttendeeId === 'main') {
          data.seat = parseInt(index, 10) + 1;
          data.seatTable = parseInt(tableId, 10);
          continue;
        }
        const attendee = data.attendees.find((attendee) => attendee.id === seatAttendeeId || attendee.tempId === seatAttendeeId);
        if (!attendee) continue;
        attendee.seat = parseInt(index, 10) + 1;
        attendee.seatTable = parseInt(tableId, 10);
      }
    }
  } else {
    // clear out seat if it was selected before
    for (const attendee of data.attendees) {
      delete attendee.seat;
    }
  }
};

export const findMissingTableAssignments = (data) => {
  const missingTableAssignments = [];
  if (data.seat === undefined) missingTableAssignments.push({ forename: data.forename, name: data.name });
  if (data.attendees?.length) {
    for (const attendee of data.attendees) {
      if (attendee?.seat !== undefined) continue;
      missingTableAssignments.push(attendee);
    }
  }
  return missingTableAssignments;
};
