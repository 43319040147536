import React, { useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { useFormState } from 'react-final-form';
import seatingImg from '../../../assets/seating_10.jpg';
import seating12Img from '../../../assets/seating_12.jpg';
import InputField from '../../../Components/InputField';

export default function TableSeatingPlan() {
  const { values } = useFormState();

  const options = useMemo(() => ([
    { name: `${values.forename} ${values.name}`, value: 'main' },
    ...values.attendees
      .map((value) => ({ name: `${value.forename} ${value.name}`, value: value.id || value.tempId })),
  ]), [values.attendees]);

  const seatings = values.seating.flat();
  const getOptions = (index, tableId) => options.filter((option) => !seatings?.includes(option.value) || values?.seating?.[tableId]?.indexOf(option.value) === index);

  const renderSeats = (table, index) => {
    const elements = [];
    for (let i = 0; i < parseInt(table.seatCount, 10); i++) {
      elements.push(
        <InputField
          type="autocomplete"
          key={`seating.${table.id || 0}.${i}`}
          name={`seating.${table.id || 0}.${i}`}
          label={`${values.eventTables?.length > 1 ? `Tisch ${index + 1} ` : ''}Sitzplatz ${i + 1}`}
          options={getOptions(i, table.id || 0)}
          noOptionsText="Keine weiteren Personen zur Auswahl"
        />,
      );
    }
    return elements;
  };

  return (
    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
      {values.eventTables.map((table, index) => (
        <>
          {values.eventTables?.length > 1 ? (
            <Grid item xs={12}>
              <Typography variant="h6" style={{ marginTop: '20px', marginBottom: '10px' }}>
                Tisch
                {' '}
                {index + 1}
              </Typography>
            </Grid>
          ) : null}
          {['10', '12'].includes(table.seatCount) && (
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <img src={table.seatCount === '12' ? seating12Img : seatingImg} alt="" style={{ width: 250 }} />
            </Grid>
          )}
          {renderSeats(table, index)}
        </>
      ))}
    </Grid>
  );
}
