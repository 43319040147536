import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import './index.css';
import { ApolloProvider } from '@apollo/client';
import { SnackbarProvider } from 'material-ui-snackbar-provider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { BrowserRouter } from 'react-router-dom';
import { ConfirmProvider } from 'material-ui-confirm';
import client from './util/ApolloProvider';
import Footer from './Components/Footer';
import Router from './Components/Router';
import { GeneralContext, GeneralStore } from './util/Store';

const Body = styled('div')({
  height: '100%',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  marginTop: 32,
});

const theme = createTheme({
  palette: {
    white: { main: '#FFFFFF' },
  },
  typography: {
    // fontFamily: '"Trebuchet MS", "Georgia", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, sans-serif',
  },
});

const generalStore = new GeneralStore();

Sentry.init({
  dsn: 'https://75451bb508a2f626fe87c0a289cd35ae@o4505790970265600.ingest.sentry.io/4505790972362752',
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div className="App">
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <ConfirmProvider>
            <GeneralContext.Provider value={generalStore}>
              <SnackbarProvider SnackbarProps={{ autoHideDuration: 4000 }}>
                <Body>
                  <BrowserRouter>
                    <Router />
                  </BrowserRouter>
                  <Footer />
                </Body>
              </SnackbarProvider>
            </GeneralContext.Provider>
          </ConfirmProvider>
        </ThemeProvider>
      </ApolloProvider>
    </div>
  </React.StrictMode>,
);
