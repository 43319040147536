/* eslint-disable react/jsx-one-expression-per-line */
import React, { useMemo } from 'react';
import {
  Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/system';
import { titlesByValue } from '../../../util/titleOptions';

export default function Summary({ invite, form }) {
  const shouldShowParticipantMail = useMemo(() => {
    if (!form?.elements) return false;
    const participantPromptElements = form.elements.find(({ type }) => type === 'participantPrompt');
    return !(participantPromptElements?.params?.noEmail || false);
  }, [form?.elements]);

  const renderAttendeeName = (attendee) => (
    <>
      {titlesByValue[attendee.title]} {attendee.forename} {attendee.name}<br />
      {shouldShowParticipantMail ? `(${attendee.email})` : ''}
    </>
  );

  return (
    <Grid item xs={12} md={6}>
      <Typography sx={{ textAlign: 'center', mt: 3, mb: 3 }} variant="h5">
        Zusammenfassung der erfassten Daten
      </Typography>
      <TableContainer component={Paper}>
        <Table
          sx={{ [`& .${tableCellClasses.root}`]: { borderBottom: 'none' } }}
        >
          <TableBody>
            <FormattedTableRow>
              <TableCell component="th" scope="row">Name</TableCell>
              <TableCell align="right">{titlesByValue[invite.title]} {invite.forename} {invite.name}</TableCell>
            </FormattedTableRow>
            {invite.company && (
              <FormattedTableRow>
                <TableCell component="th" scope="row">Firma</TableCell>
                <TableCell align="right">{invite.company}</TableCell>
              </FormattedTableRow>
            )}
            <FormattedTableRow>
              <TableCell component="th" scope="row">Adresse</TableCell>
              <TableCell align="right">
                {invite.customAttributes.street} <br />
                {invite.customAttributes.postalcode} {invite.customAttributes.city}
              </TableCell>
            </FormattedTableRow>
            <FormattedTableRow>
              <TableCell component="th" scope="row">Telefon</TableCell>
              <TableCell align="right">
                {invite.customAttributes.phone}
              </TableCell>
            </FormattedTableRow>
            <FormattedTableRow>
              <TableCell component="th" scope="row">E-Mail</TableCell>
              <TableCell align="right">
                {invite.email}
              </TableCell>
            </FormattedTableRow>
            <FormattedTableRow>
              <TableCell component="th" scope="row">Vegetarisches Essen gewünscht</TableCell>
              <TableCell align="right">{invite.customAttributes.vegetarian ? 'Ja' : 'Nein'}</TableCell>
            </FormattedTableRow>
            {invite.seat !== null && (
              <FormattedTableRow>
                <TableCell component="th" scope="row">Sitzplatz</TableCell>
                <TableCell align="right">
                  {(invite.eventTables.length > 1 ? `Tisch ${invite.eventTables.findIndex((({ id }) => id === invite.seatTableId)) + 1} Platz ` : '')}
                  {invite.seat}
                </TableCell>
              </FormattedTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {invite.inviteForm.frontendSettings?.acceptedParticipantCategoryGroupings && invite.attendees && invite.attendees.length > 0 && (
        <>
          {invite.inviteForm.frontendSettings.acceptedParticipantCategoryGroupings.map((grouping) => {
            const attendees = invite.attendees.filter(({ ticketCategory, companionId }) => (
              (grouping.ticketCategoryIds.includes(ticketCategory.id) && ((!grouping.showCompanion && !companionId) || grouping.showCompanion))
              || (grouping.showCompanion && companionId)
            ));

            if (!attendees.length) return null;
            return (
              <>
                <Typography sx={{ textAlign: 'center', mt: 3 }} variant="h6">
                  {grouping.label}
                </Typography>
                <TableContainer component={Paper}>
                  <Table
                    sx={{ [`& .${tableCellClasses.root}`]: { borderBottom: 'none' } }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" style={{ fontWeight: 'bold' }}>Gast</TableCell>
                        <TableCell align="center" style={{ fontWeight: 'bold' }}>Vegetarisches Essen gewünscht</TableCell>
                        {invite.seat !== null && (
                          <TableCell align="center" style={{ fontWeight: 'bold' }}>Sitzplatz</TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {attendees.map((attendee) => (
                        <FormattedTableRow>
                          <TableCell align="center">
                            {renderAttendeeName(attendee)}
                          </TableCell>
                          <TableCell align="center">{attendee.customAttributes.vegetarian ? 'Ja' : 'Nein'}</TableCell>
                          {invite.seat !== null && (
                            <TableCell align="center">
                              {(invite.eventTables.length > 1 ? `Tisch ${invite.eventTables.findIndex((({ id }) => id === attendee.eventTableId)) + 1}` : '')}
                              {invite.eventTables.length > 1 && <br /> }
                              {invite.eventTables.length > 1 && 'Platz '}
                              {attendee.seat}
                            </TableCell>
                          )}
                        </FormattedTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            );
          })}
        </>
      )}
      {!invite.inviteForm.frontendSettings?.acceptedParticipantCategoryGroupings && invite.attendees && invite.attendees.length > 0 && (
        <>
          <Typography sx={{ textAlign: 'center', mt: 3 }} variant="h6">
            Erfasste Gäste
          </Typography>
          <TableContainer component={Paper}>
            <Table
              sx={{ [`& .${tableCellClasses.root}`]: { borderBottom: 'none' } }}
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>Gast</TableCell>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>Vegetarisches Essen gewünscht</TableCell>
                  {invite.seat !== null && (
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>Sitzplatz</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {invite.attendees.map((attendee) => (
                  <FormattedTableRow>
                    <TableCell align="center">
                      {renderAttendeeName(attendee)}
                    </TableCell>
                    <TableCell align="center">{attendee.customAttributes.vegetarian ? 'Ja' : 'Nein'}</TableCell>
                    {invite.seat !== null && (
                      <TableCell align="center">
                        {(invite.eventTables.length > 1 ? `Tisch ${invite.eventTables.findIndex((({ id }) => id === attendee.eventTableId)) + 1}` : '')}
                        {invite.eventTables.length > 1 && <br /> }
                        {invite.eventTables.length > 1 && 'Platz '}
                        {attendee.seat}
                      </TableCell>
                    )}
                  </FormattedTableRow>

                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Grid>
  );
}

const FormattedTableRow = styled(TableRow)`
  &:last-child td, &:last-child th {
    border: 0
  }
`;
