/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const getTitles = gql`
  query titles {
    getTitles @rest(type: "[Title]", path: "external/attendeeTitles") {
      code
      name
      salutatoryAddress
      addressTitle
    }
  }
`;
