import { Grid, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { useTheme } from '@emotion/react';
import pvLogo from '../assets/pv_logo_pantone.jpg';
import ofLogo from '../assets/OF_Logo_RGB_RZ_190924.jpg';

const Heading = styled(Typography)({
  textAlign: 'center',
  // color: 'white',
  marginBottom: '50px',
});

export default function RsvpHeading() {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const logoWidth = matchesSm ? 180 : 220;
  return (
    <Heading variant="h3" sx={{ marginBottom: 6, fontSize: '2.8rem' }}>
      <Grid container sx={{ marginBottom: 2 }}>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <img src={pvLogo} alt="logo" style={{ width: logoWidth, marginRight: 20 }} />
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'left' }}>
          <img src={ofLogo} alt="logo" style={{ width: logoWidth, marginLeft: 15 }} />
        </Grid>
      </Grid>
      {/* <img src={logo} alt="logo" style={{ width: '180px' }} /> */}
      24. Operngala am 30. November 2024
    </Heading>
  );
}
