import React from 'react';
import {
  Badge, Button, Card, CardActions, CardContent, Grid, Typography,
} from '@mui/material';
import { Field, useFormState } from 'react-final-form';
import { styled } from '@mui/system';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';

export default function TableSizePrompt({ params = {}, submitType }) {
  const { values } = useFormState();
  const selections = [
    { title: 'Ich möchte einen Tisch für 10 Personen', value: '10' },
    { title: 'Ich möchte einen Tisch für 12 Personen', value: '12' },
  ];

  if (values.eventTables?.[0]?.seatCount === '14') {
    selections.push({ title: 'Ich möchte einen Tisch für 14 Personen', value: '14' });
  }
  const readonlyAfterRegistration = params?.readonlyAfterRegistration && submitType === 'update';
  return (
    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
      {values.eventTables.map((table, index) => (
        <Grid item xs={12} key={table?.id || index}>
          {values.eventTables?.length > 1 ? (
            <Grid item xs={12}>
              <Typography variant="h6" style={{ marginTop: '20px', marginBottom: '10px' }}>
                Tisch
                {' '}
                {index + 1}
              </Typography>
            </Grid>
          ) : null}
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            {selections.map((selection) => (
              <Field
                key={selection.value}
                name={`eventTables.${index}.seatCount`}
                type="radio"
                value={selection.value}
                validate={(value) => (value ? undefined : 'Required')}
                render={({
                  input: { onChange, checked },
                }) => (
                  <Grid item xs={6} md={4} lg={3}>
                    <StyledCard
                      className={checked ? 'active' : ''}
                      checked={checked}
                      onClick={() => !readonlyAfterRegistration && onChange(selection.value)}
                    >
                      <CardContent sx={{ textAlign: 'center', minHeight: '130px' }}>
                        <Badge
                          color={!checked ? 'info' : 'white'}
                          badgeContent={selection.value}
                          sx={{ fontSize: '15px' }}
                        >
                          <TableRestaurantIcon sx={{ fontSize: 50 }} />
                        </Badge>
                        <Typography variant="subtitle1" component="p">{selection.title}</Typography>
                      </CardContent>
                      <CardActions sx={{ justifyContent: 'center' }}>
                        <Button variant="contained" disabled={readonlyAfterRegistration || checked} onClick={() => onChange(selection.value)}>
                          {checked ? 'Ausgewählt' : 'Auswählen'}
                        </Button>
                      </CardActions>
                    </StyledCard>
                  </Grid>
                )}
              />
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

const StyledCard = styled(Card)`
  min-height: 220px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;

  svg {
    fill: rgba(0, 0, 0, 0.6);
  }
  p {
    font-size: 17px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 1.5;
  }

  ${(props) => props.checked && `
    background-color: rgba(25, 118, 210, 0.9);
    border: 1px solid rgba(25, 118, 210, 0.9);
    svg {
      fill: white;
    }
    p {
      color: white;
    }
    button {
      color: #a6a6a6 !important;
      background-color: #f4f4f4 !important;
    }
  `}
`;
