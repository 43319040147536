import { gql } from '@apollo/client';

export const getInviteGql = gql`
  query invite($inviteCode: String!) {
    getInvite (inviteCode: $inviteCode)
      @rest(type: "Invite", path: "invites/{args.inviteCode}") {
        id
        title
        forename  
        name
        email
        company
        seat
        seatTableId
        status
        customAttributes
        maxCustomerCount
        usedCount
        waitingList
        attendees {
          id
          title
          forename
          name
          email
          alreadyUsed
          customAttributes
          eventTableId
          seat
          waitingList
          companionId
          ticketCategory {
            id
            name
          }
        }
        inviteCategory {
          maxCustomerCount
          name
        }
        inviteForm {
          name
          title
          description
          successDescription
          status
          frontendSettings
          elements {
            type
            title
            description
            promptBy
            params
          }
        }
        eventTable
        eventTables
        quotas
    }
  }
`;

export const getInviteFormGql = gql`
  query invite($formInviteId: String!) {
    getInviteForm (formInviteId: $formInviteId)
      @rest(type: "InviteForm", path: "external/inviteForms/{args.formInviteId}") {
        name
        title
        description
        successDescription
        status
        elements {
          type
          title
          description
          promptBy
          params
        }
    }
  }
`;

export const updateInviteGql = gql`
  mutation updateInvite($inviteCode: String!, $input: PublishablePostInput!) {
    createInvite (inviteCode: $inviteCode,  input: $input) @rest(
      path: "invites/{args.inviteCode}",
      method: "POST"
    ) { NoResponse }
  }
`;
