import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export default function NotFound() {
  return (
    <>
      <Heading variant="h4">
        <ErrorOutlineIcon color="error" sx={{ fontSize: '5rem' }} />
        <br />
        Einladung nicht gefunden
      </Heading>
      <Typography variant="body1" textAlign="center">
        Die von Ihnen aufgerufene Einladung konnte nicht gefunden!
        <br />
        Bitte überprüfen Sie die URL und versuchen Sie es erneut.
      </Typography>
    </>
  );
}

const Heading = styled(Typography)({
  textAlign: 'center',
  // color: 'white',
  marginBottom: '30px',
});
