import React from 'react';
import {
  Divider, Grid, IconButton, Tooltip, Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFormState } from 'react-final-form';
import InputField from '../../../Components/InputField';

export default function ParticipantPromptGuest({
  attendees, handleDelete, startParticipantNumber, noEmail, showAddressFields, readOnlyAfterCreation, noAttendeeCountChange, attendeeLabel, attendeeEmailLabel,
}) {
  const { values } = useFormState();
  const getFields = (index, attendee, allFieldsDisabled) => {
    const fields = [
      {
        name: `attendees.${index}.title`,
        label: 'Anrede',
        type: 'titleSelect',
        required: true,
        gridSize: { xs: 12, md: 3 },
        disabled: attendee?.formOptions?.disabled || allFieldsDisabled,
      },
      {
        name: `attendees.${index}.forename`,
        label: 'Vorname',
        required: true,
        gridSize: { xs: 12, md: 4 },
        disabled: attendee?.formOptions?.disabled || allFieldsDisabled,
      },
      {
        name: `attendees.${index}.name`,
        label: 'Nachname',
        required: true,
        gridSize: { xs: 12, md: 5 },
        disabled: attendee?.formOptions?.disabled || allFieldsDisabled,
      },
    ];

    if (showAddressFields) {
      fields.push(
        {
          name: `attendees.${index}.customAttributes.street`,
          label: 'Straße',
          required: true,
          gridSize: { xs: 12 },
          disabled: allFieldsDisabled,
        },
        {
          name: `attendees.${index}.customAttributes.postalcode`,
          label: 'PLZ',
          type: 'number',
          required: true,
          gridSize: { xs: 12, md: 3 },
          disabled: allFieldsDisabled,
        },
        {
          name: `attendees.${index}.customAttributes.city`,
          label: 'Ort',
          required: true,
          gridSize: { xs: 12, md: 9 },
          disabled: allFieldsDisabled,
        },
        {
          name: `attendees.${index}.customAttributes.phone`,
          label: 'Telefon',
          type: 'tel',
          required: true,
          gridSize: { xs: 12 },
          disabled: allFieldsDisabled,
        },
      );
    }

    if (!noEmail) {
      fields.push({
        name: `attendees.${index}.email`,
        label: attendeeEmailLabel || 'E-Mail (an diese E-Mail wird die Einlasskarte gesendet)',
        type: 'email',
        required: true,
        gridSize: { xs: 12, md: 7 },
        disabled: allFieldsDisabled,
      });
    }

    fields.push({
      name: `attendees.${index}.customAttributes.vegetarian`,
      label: 'Vegetarisches Essen',
      type: 'checkbox',
      gridSize: !noEmail ? { xs: 12, md: 5 } : undefined,
      disabled: allFieldsDisabled,
    });
    return fields;
  };

  return (
    <>
      {attendees.map((attendee, index) => {
        const allFieldsDisabled = readOnlyAfterCreation && attendee.id;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Grid container key={index} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h6" style={{ marginTop: '20px', marginBottom: '10px' }}>
                {attendeeLabel || 'Gast'}
                {' '}
                {startParticipantNumber + 1 + index}
                {values.waitingList || attendee.waitingList ? ' (Warteliste)' : ''}
              </Typography>
            </Grid>
            <Grid item xs={allFieldsDisabled || noAttendeeCountChange ? 12 : 11}>
              <Grid container spacing={2}>
                {getFields(attendee.index, attendee, allFieldsDisabled).map((field) => (
                  <InputField key={field.name} {...field} />
                ))}
              </Grid>
            </Grid>

            {(!allFieldsDisabled && !noAttendeeCountChange) && (
              <Grid item xs={1} sx={{ textAlign: 'center' }}>
                <Tooltip title={`${attendeeLabel || 'Gast'} löschen`}>
                  <IconButton aria-label="delete" size="large" color="error" onClick={() => handleDelete(attendee.index, null, values)}>
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            <Grid item xs={12} sx={{ marginTop: '30px' }}>
              <Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.5)' }} />
            </Grid>
          </Grid>
        );
      })}
    </>
  );
}
