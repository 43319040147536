import React from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid, Typography, useMediaQuery,
} from '@mui/material';
import { Field, useForm } from 'react-final-form';
import { styled, useTheme } from '@mui/system';
import * as Muicon from '@mui/icons-material';
import { red } from '@mui/material/colors';

import PersonIcon from '@mui/icons-material/Person';

export default function SwitchForm({ params, invite, submitType }) {
  const theme = useTheme();
  const form = useForm();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

  let cardHeight = matchesSm ? 260 : 220;
  let contentHeight = matchesSm ? 160 : 130;
  const quotaError = invite.quotas.find(({ main }) => !main);
  if (quotaError) {
    contentHeight += 40;
    cardHeight += 40;
  }
  return (
    <Grid container spacing={matchesSm ? 1 : 2} sx={{ marginTop: '20px' }}>
      <Grid item xs={12}>
        <Grid container spacing={matchesSm ? 1 : 2} justifyContent="center" alignItems="center">
          {params.selections.map((selection, index) => {
            const quota = invite.quotas.find(({ formId }) => formId === parseInt(selection.action.id, 10));
            let showWaitlistRibbon = false;
            const formSwitchId = invite.customAttributes.switchForm ? parseInt(invite.customAttributes.switchForm.replace('formSwitch:', ''), 10) : null;
            if (quota?.main === false && (!formSwitchId || (formSwitchId && quota.formId !== formSwitchId))) showWaitlistRibbon = true;
            return (
              <Field
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                name="customAttributes.switchForm"
                type="radio"
                value={`${selection.action.type}:${selection.action.id || ''}`}
                render={({
                  input: { onChange, checked },
                }) => {
                  const Icon = Muicon[selection.icon] || PersonIcon;
                  return (
                    <Grid item xs={6} md={4} lg={3}>
                      <StyledCard
                        className={checked ? 'active' : ''}
                        checked={checked}
                        height={cardHeight}
                        onClick={() => {
                          if (submitType === 'update') return;
                          form.change('waitingList', showWaitlistRibbon);
                          onChange(`${selection.action.type}:${selection.action.id || ''}`);
                        }}
                      >
                        {showWaitlistRibbon && <Ribbon><span>Warteliste</span></Ribbon>}
                        <CardContent sx={{ textAlign: 'center', height: contentHeight }}>
                          <Icon sx={{ fontSize: 50 }} />
                          <Typography variant="subtitle1" component="p">{selection.title}</Typography>
                        </CardContent>
                        <CardActions sx={{ justifyContent: 'center' }}>
                          <Button variant="contained" disabled={checked || submitType === 'update'} onClick={() => onChange(`${selection.action.type}:${selection.action.id || ''}`)}>
                            {checked ? 'Ausgewählt' : 'Auswählen'}
                          </Button>
                        </CardActions>
                      </StyledCard>
                    </Grid>
                  );
                }}
              />
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
}

const Ribbon = styled('div')`
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  top: -10px;
  right: -10px;

  ::before,
  ::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #2980b9;
    border-top-color: transparent;
    border-right-color: transparent;
  }

  ::before {
    top: 0;
    left: 0;
  }

  ::after {
    bottom: 0;
    right: 0;
  }

  span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: ${red[500]};
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #FFF;
    font: 700 14px/1 'Roboto';
    text-transform: uppercase;
    text-align: center;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    left: -20px;
    top: 35px;
    transform: rotate(45deg);
  }

`;

const StyledCard = styled(Card)`
  height: ${(props) => props.height}
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  hyphens: auto;
  position: relative;

  svg {
    fill: rgba(0, 0, 0, 0.6);
  }
  p {
    font-size: 17px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 1.5;
  }

  ${(props) => props.checked && `
    background-color: #397FFB;
    border: 1px solid rgba(25, 118, 210, 0.9);
    svg {
      fill: white;
    }
    p {
      color: white;
    }
    button {
      color: #a6a6a6 !important;
      background-color: #f4f4f4 !important;
    }
  `}
`;
