/* eslint-disable react/jsx-one-expression-per-line */
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { Container, styled } from '@mui/system';
import {
  Button, Grid, Paper, Typography, Stack, CircularProgress, useMediaQuery,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import JsxParser from 'react-jsx-parser';
import { useTheme } from '@emotion/react';
import { getInviteFormGql, getInviteGql } from '../InviteResponse/gql';
import Summary from './components/Summary';
import RsvpHeading from '../../Components/RsvpHeading';
import { GeneralContext } from '../../util/Store';

const PaperBox = styled(Paper)((props) => ({
  padding: props.matchesSm ? '18px 18px 18px 18px' : '36px 36px 36px 36px',
  position: 'relative',
  paddingBottom: 10,
}));

const ErrorTypo = styled(Typography)({
  textAlign: 'center',
  marginTop: '35vh',
});

export default function Accepted() {
  const { inviteCode } = useParams();
  const snackbar = useSnackbar();
  const [form, setForm] = useState(null);
  const [overrideFormId, setOverrideFormId] = useState(null);
  const theme = useTheme();
  const generalStore = useContext(GeneralContext);
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    data,
    loading,
  } = useQuery(getInviteGql, {
    fetchPolicy: 'no-cache',
    variables: {
      inviteCode,
    },
    onError: () => snackbar.showMessage('Fehler beim laden.'),
    onCompleted: ({ getInvite }) => {
      if (getInvite.customAttributes?.switchForm && getInvite.customAttributes?.switchForm.startsWith('formSwitch:')) {
        setOverrideFormId(getInvite.customAttributes.switchForm.replace('formSwitch:', ''));
      }
      setForm(getInvite.inviteForm);
    },
  });

  const { loading: inviteFormLoading } = useQuery(getInviteFormGql, {
    skip: !overrideFormId,
    fetchPolicy: 'no-cache',
    variables: {
      formInviteId: overrideFormId,
    },
    onCompleted: ({ getInviteForm }) => {
      setForm(getInviteForm);
    },
  });

  const invite = useMemo(() => data?.getInvite, [data?.getInvite]);

  useEffect(() => {
    if (!data?.getInvite) return;
    generalStore.setContactInfo(data?.getInvite.inviteForm.frontendSettings.contactInfo);
  }, [data?.getInvite]);

  if (!invite || loading || inviteFormLoading) {
    return (
      <Stack alignItems="center" sx={{ mt: 5 }}>
        <CircularProgress />
      </Stack>
    );
  }

  if (!inviteCode) return (<ErrorTypo variant="h2">Fehlerhafter Link</ErrorTypo>);
  return (
    <Container maxWidth="lg" sx={{ paddingX: '8px' }}>
      <RsvpHeading />
      <PaperBox sx={{ pb: 4, mb: 1 }} elevation={4} matchesSm={matchesSm}>
        <Grid container direction="row" justifyContent="center" sx={{ mb: 2 }}>
          <CheckCircleOutlineIcon color="success" sx={{ fontSize: 80 }} />
        </Grid>
        <Typography sx={{ textAlign: 'center', mb: 2 }} variant="h4">
          {invite.waitingList ? ('Vielen Dank für die Registrierung,') : 'Vielen Dank für die Registrierung'}
          {invite.waitingList ? <br /> : ''}
          {invite.waitingList ? 'Sie befinden sich nun auf der Warteliste' : ''}
        </Typography>
        <Grid container direction="row" justifyContent="center">
          <JsxParser
            components={{
              Typography, Stack, Summary, Grid,
            }}
            jsx={form?.successDescription}
            bindings={{ invite, form }}
            renderInWrapper={false}
          />
        </Grid>
        {/* document.cookie.includes('isAdmin=true') && */ (
          <Grid container direction="row" justifyContent="center" sx={{ pt: 4 }}>
            <Button
              variant="outlined"
              to={`/einladung/${inviteCode}/response?update=true`}
              component={Link}
            >
              Änderungen vornehmen
            </Button>
          </Grid>
        )}
      </PaperBox>
    </Container>
  );
}
