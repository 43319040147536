import React from 'react';
import { Grid } from '@mui/material';
import InputField from '../../../Components/InputField';

export default function AttendanceConfirmation({ params = {}, submitType }) {
  return (
    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
      <Grid item>
        <InputField
          name="status"
          type="radio"
          required
          disabled={params?.readonlyAfterRegistration && submitType === 'update'}
          options={[
            { name: 'Ich möchte die Operngala besuchen', value: 'accepted' },
            { name: 'Ich kann nicht kommen und möchte absagen', value: 'declined' },
          ]}
        />
      </Grid>
    </Grid>
  );
}
