import { createContext } from 'react';
import { observable, makeObservable, action } from 'mobx';

export class GeneralStore {
  constructor() {
    this.contactInfo = null;
    this.openPrivacyPolicy = false;
    makeObservable(this, {
      contactInfo: observable,
      setContactInfo: action,
      openPrivacyPolicy: observable,
      setOpenPrivacyPolicy: action,
    });
  }

  setOpenPrivacyPolicy(openPrivacyPolicy) {
    this.openPrivacyPolicy = openPrivacyPolicy;
  }

  setContactInfo(contactInfo) {
    this.contactInfo = contactInfo;
  }
}

export const GeneralContext = createContext();
