import React from 'react';
import {
  Autocomplete,
  Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField,
} from '@mui/material';
import { Field } from 'react-final-form';
import validator from 'validator';
import { useQuery } from '@apollo/client';
import { titleOptions } from '../util/titleOptions';
import { getTitles } from '../gql';

const requiredValidation = (value) => (value || value === 0 ? undefined : 'Erforderlich');
const emailValidation = (value) => (!value || validator.isEmail(value) ? undefined : 'Ungültige E-Mail Adresse');
const composeValidators = (...validators) => (value) => validators.reduce((error, validator) => error || validator(value), undefined);

export default function InputField({
  name,
  label,
  disabled,
  gridSize = { xs: 12, lg: 6 },
  type = null,
  required = false,
  options = [],
  placeholder,
  noOptionsText,
}) {
  const { data: dynamicTitles } = useQuery(getTitles, { skip: type !== 'titleSelect' });

  if (type === 'checkbox') {
    return (
      <Field
        name={name}
        type="checkbox"
        render={({ input }) => (
          <Grid item {...gridSize}>
            <FormControlLabel
              control={<Checkbox />}
              label={label}
              disabled={disabled}
              sx={{ marginTop: '7px', marginLeft: 0 }}
              {...input}
            />
          </Grid>
        )}
      />
    );
  }

  if (type === 'radio') {
    return (
      <FormControl>
        {label && <FormLabel id="demo-radio-buttons-group-label">{label}</FormLabel>}
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
        >
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              control={(
                <Field
                  name={name}
                  type="radio"
                  render={({
                    input: {
                      name, value, onChange, checked, ...restInput
                    },
                  }) => (
                    <Radio
                      name={name}
                      value={value}
                      onChange={onChange}
                      checked={checked}
                      inputProps={restInput}
                      required={required}
                      disabled={disabled}
                    />
                  )}
                />
              )}
              label={option.name}
              value={option.value}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }

  const validators = [];
  if (required) validators.push(requiredValidation);
  if (type === 'email') validators.push(emailValidation);

  if (type === 'titleSelect') {
    return (
      <Field
        name={name}
        validate={composeValidators(...validators)}
        render={({ input, meta }) => (
          <Grid item {...gridSize}>
            <FormControl fullWidth required={required} error={meta.touched && (meta.error || meta.submitError)}>
              <InputLabel id="demo-simple-select-label">{label}</InputLabel>
              <Select
                label={label}
                disabled={disabled}
                {...input}
                required={false}
              >
                {dynamicTitles?.getTitles?.length
                  ? dynamicTitles?.getTitles.map((title) => (<MenuItem value={title.code}>{title.name}</MenuItem>))
                  : titleOptions.map((option) => (<MenuItem value={option.value}>{option.name}</MenuItem>))}
              </Select>
              {meta.error && meta.touched && <FormHelperText>{meta.error || meta.submitError}</FormHelperText>}
            </FormControl>
          </Grid>
        )}
      />
    );
  }

  if (type === 'select') {
    return (
      <Field
        name={name}
        validate={composeValidators(...validators)}
        render={({ input, meta }) => (
          <Grid item {...gridSize}>
            <FormControl fullWidth error={meta.touched && (meta.error || meta.submitError)}>
              <InputLabel id="demo-simple-select-label">{label}</InputLabel>
              <Select
                label={label}
                /* required={required} */
                {...input}
              >
                {placeholder && <MenuItem disabled value=""><em>{placeholder}</em></MenuItem>}
                {options.map((option) => (<MenuItem value={option.value}>{option.name}</MenuItem>))}
              </Select>
              {meta.error && meta.touched && <FormHelperText>{meta.error || meta.submitError}</FormHelperText>}
            </FormControl>
          </Grid>
        )}
      />
    );
  }

  if (type === 'autocomplete') {
    return (
      <Field
        name={name}
        validate={composeValidators(...validators)}
        render={({ input, meta }) => {
          let selectedOption = null;
          if (input.value !== undefined) {
            selectedOption = options.find((option) => option.value === input.value);
            if (selectedOption) selectedOption = { label: selectedOption.name, value: selectedOption.value };
          }
          return (
            <Grid item {...gridSize}>
              <Autocomplete
                required={required}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={label}
                    error={meta.touched && meta.error}
                    helperText={meta.touched && meta.error}
                  />
                )}
                options={options.map((option) => ({ label: option.name, value: option.value }))}
                {...input}
                value={selectedOption}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                noOptionsText={noOptionsText}
                sx={{
                  '& .MuiAutocomplete-clearIndicator': {
                    visibility: 'visible !important',
                  },
                }}
                onChange={(_event, newValue) => {
                  input.onChange(newValue?.value);
                }}
              />
            </Grid>
          );
        }}
      />
    );
  }

  return (
    <Field
      name={name}
      validate={composeValidators(...validators)}
      render={({ input, meta }) => (
        <Grid item {...gridSize}>
          <TextField
            error={meta.touched && !meta.dirtySinceLastSubmit && (meta.error || meta.submitError)}
            type={type}
            label={`${label} ${required ? '*' : ''}`}
            variant="outlined"
            fullWidth
            disabled={disabled}
            placeholder={placeholder}
            helperText={meta.touched && !meta.dirtySinceLastSubmit && (meta.error || meta.submitError)}
            {...input}
          />
        </Grid>
      )}
    />
  );
}
