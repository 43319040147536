import React from 'react';
import { Route, Routes } from 'react-router-dom';
import InviteResponse from '../pages/InviteResponse';
import Error404Page from '../pages/404';
import Welcome from '../pages/Welcome';
import Declined from '../pages/Declined';
import Accepted from '../pages/Accepted';
import Error from '../pages/Error';
import IndexPage from '../pages/Index';
import Closed from '../pages/Closed';

export default function Router() {
  return (
    <Routes>
      <Route path="/einladung/:inviteCode" element={<Welcome />} />
      <Route path="/e/:inviteCode" element={<Welcome />} />
      <Route path="/einladung/:inviteCode/response" element={<InviteResponse />} />
      <Route path="/einladung/:inviteCode/declined" element={<Declined />} />
      <Route path="/einladung/:inviteCode/accepted" element={<Accepted />} />
      <Route path="/einladung/:inviteCode/closed" element={<Closed />} />
      <Route path="/einladung/:inviteCode/error" element={<Error />} />
      <Route path="/" element={<IndexPage />} />
      <Route path="*" element={<Error404Page />} />
    </Routes>
  );
}
