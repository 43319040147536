import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import config from '../config';
import { GeneralContext } from '../util/Store';

const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: 'auto',
    paddingTop: '50px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    // Avoid clipping with third party bottom content like arena chat.
    marginBottom: 32,
  },
  flex: {
    margin: 0,
    display: 'flex',
    flexFlow: 'column wrap',
    justifyContent: 'center',
    padding: '10px',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  flexItem: {
    flexBasis: 'auto',
    textAlign: 'center',
    padding: '10px',
    borderLeft: 'solid 0px white',
    [theme.breakpoints.up('sm')]: {
      borderLeft: 'solid 0px white',
    },
  },
  a: {
    color: theme.palette.background.contrastText || '#000',
    '&:hover': {
      color: theme.palette.background.hover || '#ccc',
    },
    fontSize: '16px',
    fontWeight: 400,
  },
}));

export default observer(() => {
  const classes = useStyles();
  const generalStore = useContext(GeneralContext);
  const [openPrivacy, setOpenPrivacy] = useState(false);

  useEffect(() => {
    if (!generalStore.openPrivacyPolicy) return;
    setOpenPrivacy(true);
    generalStore.setOpenPrivacyPolicy(false);
  }, [generalStore.openPrivacyPolicy]);

  return (
    <section className={classes.footer}>
      <Grid container>
        {generalStore.contactInfo && (
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
            <div style={{ width: 300 }}>
              <Typography variant="body1" sx={{ marginBottom: 2 }}>
                <b>Service-Kontakt:</b>
                <br />
                {generalStore.contactInfo.name}
                <br />
                Tel.:
                {' '}
                <a href={`tel:${generalStore.contactInfo.tel}`}>{generalStore.contactInfo.telDisplay}</a>
                <br />
                E-Mail:
                {' '}
                <a href={`mailto:${generalStore.contactInfo.email}`}>{generalStore.contactInfo.email}</a>
              </Typography>
              <Divider />
            </div>
          </Grid>
        )}
        <Grid item xs={12}>
          <div className={classes.flex}>
            {config.footerLinks.map(({ name, link, type }) => (
              <div className={classes.flexItem} key={name}>
                <a
                  className={classes.a}
                  href={link}
                  onClick={type === 'privacy' ? () => setOpenPrivacy(true) : undefined}
                  target="_blank"
                  rel="noreferrer"
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                  <Typography variant="subtitle2">
                    {name}
                  </Typography>
                </a>
              </div>
            ))}
          </div>
        </Grid>
      </Grid>
      <Dialog
        open={openPrivacy}
        keepMounted
        onClose={() => setOpenPrivacy(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Datenschutz</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Zur Durchführung und Organisation der Operngala verarbeiten wir personenbezogene Daten der Teilnehmer.
            Unter anderem veröffentlichen wir eine Gästeliste (Titel, Vor-, Nachname) im Programmheft und stellen personalisierte Eintrittskarten mit Tischplatzierungs-Nummern her.
            <br />
            <br />
            Die Gästeliste geben wir an die nachfolgenden Dienstleister weiter:
            <ul>
              <li>Druckerei (zur Herstellung der Printprodukte)</li>
              <li>Fotograf (als Orientierung für Gruppenfotos zur Berichterstattung)</li>
              <li>Caterer (zur Berücksichtigung der Essenswünsche)</li>
              <li>Sicherheitsdienst (zur Umsetzung der Veranstaltungssicherheit)</li>
            </ul>
            Bei der Veranstaltung werden Foto- und Videoaufnahmen für Presseberichte und zu Marketingzwecken erstellt (Veröffentlichung in Printmedien und auf digitalen Plattformen der Oper Frankfurt).Die Gastgeber werden ersucht, ihre Gäste über die Datenschutzhinweise zu informieren.
            <br />
            <br />
            Wird eine namentliche Erwähnung auf der Gästeliste und/oder Veröffentlichung von Fotos bzw. Videoaufnahmen nicht gewünscht, bitten wir bis zum 20.11.2024 um Mitteilung an den Patronatsverein.
          </DialogContentText>
          <br />
          <DialogContentText>
            Für weitere Informationen zum Datenschutz wenden Sie sich bitte an
            {' '}
            {generalStore?.contactInfo?.privacyPolicy ? generalStore.contactInfo.privacyPolicy : 'den Patronatsverein'}
            .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPrivacy(false)}>Schließen</Button>
        </DialogActions>
      </Dialog>
    </section>
  );
});
