export const titleOptions = [
  { name: 'Herr', value: 'mr' },
  { name: 'Frau', value: 'mrs' },
  { name: 'Herr Dr.', value: 'mrDr' },
  { name: 'Frau Dr.', value: 'mrsDr' },
  { name: 'Herr Prof.', value: 'mrProf' },
  { name: 'Frau Prof.', value: 'mrsProf' },
];

export const titlesByValue = {
  mr: 'Herr',
  mrs: 'Frau',
  mrDr: 'Herr Dr.',
  mrsDr: 'Frau Dr.',
  mrProf: 'Herr Prof.',
  mrsProf: 'Frau Prof.',
  mrDrIng: 'Herr Dr.-Ing',
  mrsDrIng: 'Frau Dr.-Ing',
  staatssekretärDr: 'Staatssekretär Dr.',
  staatssekretärinDr: 'Staatssekretärin Dr.',
};
