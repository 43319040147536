import React, { useContext, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { Container, Stack, styled } from '@mui/system';
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import JsxParser from 'react-jsx-parser';
import { getInviteGql } from '../InviteResponse/gql';
import NotFound from '../../Components/NotFound';
import RsvpHeading from '../../Components/RsvpHeading';
import { GeneralContext } from '../../util/Store';

const PaperBox = styled(Paper)({
  padding: '36px 36px 36px 36px',
  paddingBottom: 10,
  position: 'relative',
});

export default function Welcome() {
  const { inviteCode } = useParams();
  const snackbar = useSnackbar();
  const generalStore = useContext(GeneralContext);
  const navigate = useNavigate();
  const {
    data,
    loading,
  } = useQuery(getInviteGql, {
    fetchPolicy: 'no-cache',
    variables: {
      inviteCode,
    },
    onCompleted: ({ getInvite }) => {
      if (getInvite.status === 'accepted') {
        navigate(`/einladung/${inviteCode}/accepted`);
        return;
      }
      if (getInvite.status === 'declined') {
        navigate(`/einladung/${inviteCode}/declined`);
      }
    },
    onError: () => snackbar.showMessage('Fehler beim laden.'),
  });

  const invite = useMemo(() => data?.getInvite, [data?.getInvite]);
  useEffect(() => {
    if (!data?.getInvite) return;
    generalStore.setContactInfo(data?.getInvite.inviteForm.frontendSettings.contactInfo);
  }, [data?.getInvite]);

  const renderContent = () => {
    if (loading) {
      return (
        <Stack alignItems="center" sx={{ mt: 5 }}>
          <CircularProgress />
        </Stack>
      );
    }

    if (!inviteCode || !invite) return (<NotFound />);

    return (
      <>
        <JsxParser
          components={{ Typography }}
          jsx={invite.inviteForm.description}
        />
        <Grid container direction="row" justifyContent="center" sx={{ pt: 4 }}>
          <Button
            variant="contained"
            to={`/einladung/${inviteCode}/response`}
            component={Link}
          >
            Zur Registrierung
          </Button>
        </Grid>
      </>
    );
  };

  return (
    <Container maxWidth="lg">
      <RsvpHeading />
      <PaperBox sx={{ pb: 4, mb: 2 }} elevation={4}>
        {renderContent()}
      </PaperBox>
    </Container>
  );
}
