import React from 'react';
import { Container, styled } from '@mui/system';
import {
  Alert,
  Button, Grid, Paper, Typography,
} from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import RsvpHeading from '../../Components/RsvpHeading';
import InputField from '../../Components/InputField';
import { getInviteGql } from '../InviteResponse/gql';

const PaperBox = styled(Paper)({
  padding: '36px 36px 36px 36px',
  position: 'relative',
});

export default function IndexPage() {
  const [getInvite] = useLazyQuery(getInviteGql, { fetchPolicy: 'network-only' });

  const onSubmit = async (values) => {
    const { data } = await getInvite({
      variables: { inviteCode: values.inviteCode },
    });

    if (!data.getInvite) {
      return {
        inviteCode: 'Ungültiger Einladungscode',
        [FORM_ERROR]: 'Die Einladung konnte nicht gefunden werden. Bitte überprüfen Sie den Code und versuchen Sie es erneut.',
      };
    }
    window.location.href = `/einladung/${values.inviteCode}`;
    return {};
  };

  return (
    <Container maxWidth="lg">
      <RsvpHeading />
      <PaperBox sx={{ pb: 4, mb: 2 }} elevation={4}>
        <Typography variant="h5" align="center" sx={{ mb: 4 }}>
          Willkommen bei der Registrierung zur 24. Operngala.
        </Typography>
        <Typography variant="body1" align="center" sx={{ mb: 4 }}>
          Bitte geben Sie Ihren persönlichen Einladungscode ein.
          <br />
          Diesen finden Sie auf Ihrer Einladungskarte.
        </Typography>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, submitError, dirtySinceLastSubmit }) => (
            <form onSubmit={handleSubmit}>
              {submitError && !dirtySinceLastSubmit && (
                <Grid container direction="row" justifyContent="center" sx={{ mb: 4 }}>
                  <Alert severity="error">{submitError}</Alert>
                </Grid>
              )}
              <Grid container direction="row" justifyContent="center">
                <InputField
                  name="inviteCode"
                  required
                  label="Einladungscode"
                />
              </Grid>

              <Grid container direction="row" justifyContent="center" sx={{ mt: 4 }}>
                <Button
                  variant="contained"
                  type="submit"
                >
                  Einladung aufrufen
                </Button>
              </Grid>
            </form>
          )}
        />
      </PaperBox>
    </Container>
  );
}
