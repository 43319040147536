/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo } from 'react';
import {
  Grid, Typography, Fab, Divider, Alert, Stack,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useConfirm } from 'material-ui-confirm';
import { useForm, useFormState } from 'react-final-form';
import JsxParser from 'react-jsx-parser';
import { v4 as uuidv4 } from 'uuid';
import InputField from '../../../Components/InputField';
import ParticipantPromptGuest from './ParticipantPromptGuest';

export default function ParticipantPrompt({
  invite,
  params: {
    participantCategoryGroupings = null,
    noGuestPrefilling = false,
    noEmail = false,
    noAttendeeCountChangeAfterRegistration = false,
    noAttendeeCreation = false,
    mainAttendeeReadOnly = true,
    emailLabel = null,
  },
  submitType,
}) {
  const confirm = useConfirm();
  const form = useForm();
  const { values } = useFormState();
  const maxQuotaCount = useMemo(() => {
    const formId = values.customAttributes?.switchForm?.replace('formSwitch:', '');
    if (!formId) return null;
    if (!invite.quotas) return null;
    const quota = invite.quotas.find((quota) => quota.formId === parseInt(formId, 10));
    if (!quota) return null;
    return quota.maxCount;
  }, [values.customAttributes?.switchForm, invite.quotas]);

  const noAttendeeCountChange = noAttendeeCountChangeAfterRegistration && submitType === 'update';
  const maxAttendeeCount = values.eventTables?.reduce((acc, table) => { acc += parseInt(table.seatCount, 10); return acc; }, 0) || null;
  const currentAttendeeCount = values.attendees.length + 1;
  const attendeeLimitReached = maxAttendeeCount && currentAttendeeCount >= maxAttendeeCount;

  const mainAttendee = [
    {
      name: 'title',
      label: 'Anrede',
      type: 'titleSelect',
      required: true,
      disabled: mainAttendeeReadOnly === undefined ? true : mainAttendeeReadOnly,
      gridSize: { xs: 12, md: 3 },
    },
    {
      name: 'forename',
      label: 'Vorname',
      required: true,
      disabled: mainAttendeeReadOnly === undefined ? true : mainAttendeeReadOnly,
      gridSize: { xs: 12, md: 4 },
    },
    {
      name: 'name',
      label: 'Nachname',
      required: true,
      disabled: mainAttendeeReadOnly === undefined ? true : mainAttendeeReadOnly,
      gridSize: { xs: 12, md: 5 },
    },
    {
      name: 'email',
      label: emailLabel || 'E-Mail (an diese E-Mail wird die Einlasskarte gesendet)',
      type: 'email',
      required: true,
      disabled: mainAttendeeReadOnly === undefined ? true : mainAttendeeReadOnly,
      gridSize: { xs: 12, md: 7 },
    },
    {
      name: 'customAttributes.vegetarian',
      label: 'Vegetarisches Essen',
      type: 'checkbox',
      gridSize: { xs: 12, md: 5 },
    },
  ];

  const handleAdd = async (grouping, values) => {
    let waitingList = false;

    if (attendeeLimitReached) {
      await confirm({
        title: 'Maximale Anzahl erreicht',
        description: `Sie haben die maximale Anzahl von ${maxAttendeeCount} Gästen erreicht! Es können keine weiteren Gäste hinzugefügt werden.`,
        hideCancelButton: true,
        confirmationText: 'Zurück',
      });
      return;
    }

    if (maxQuotaCount && (values.attendees.length + 2) > maxQuotaCount && !values.waitingList && !values.eventTables?.[0]?.id) {
      await confirm({
        title: 'Warteliste',
        description: `
          Aufgrund der limitierten Plätze können Sie maximal ${(maxQuotaCount - 1) === 1 ? 'ein zusätzlichen Gast' : `${maxQuotaCount - 1} zusätzliche Gäste`} registrieren.
          Sie haben die Möglichkeit, sich mit all Ihren Gästen auf die Warteliste setzen zu lassen oder keine weiteren Gäste mehr hinzuzufügen.
        `,
        confirmationText: 'Mich und meine Gäste auf die Warteliste setzen',
        cancellationText: 'Nicht hinzufügen',
      });
      form.change('waitingList', true);
      waitingList = true;
    }

    const uuid = uuidv4();
    const newAttendees = [
      ...values.attendees,
      {
        tempId: uuid,
        forename: '',
        name: '',
        email: '',
        customAttributes: { vegetarian: false },
        waitingList,
        ticketCategory: { id: grouping?.ticketCategoryId },
      },
    ];
    if (grouping?.addCompanion) {
      newAttendees.push({
        tempId: uuidv4(),
        forename: '',
        name: '',
        email: '',
        customAttributes: { vegetarian: false },
        waitingList,
        companionTempId: uuid,
        ticketCategory: { id: grouping?.companionTicketCategoryId },
      });
    }
    form.change('attendees', newAttendees);
  };

  useEffect(() => {
    const attendees = [...values.attendees];
    if (!attendees.length
      && invite.customAttributes.forenameCompanion
      && invite.customAttributes.nameCompanion) {
      attendees.push({
        tempId: uuidv4(),
        title: invite.customAttributes.titleCompanion,
        forename: invite.customAttributes.forenameCompanion,
        name: invite.customAttributes.nameCompanion,
        email: invite.email,
        ticketCategory: { id: participantCategoryGroupings[0].ticketCategoryId },
        // formOptions: { disabled: true },
      });
      form.change('attendees', attendees);
    }
  }, []);

  const handleDelete = async (index, grouping, values) => {
    console.log('handleDelete', values, index);
    const attendee = values.attendees[index];
    try {
      if (attendee.forename || attendee.name) {
        await confirm({
          title: `${grouping?.attendeeLabel || 'Gast'} löschen`,
          description: `Wollen Sie wirklich ${grouping?.attendeeLabel === 'Begleitperson' ? 'die Begleitperson' : 'den Gast'} ${attendee.forename} ${attendee.name} löschen? Dieser Vorgang ist unwiderruflich.`,
          confirmationText: 'Löschen',
          cancellationText: 'Abbrechen',
          confirmationButtonProps: { color: 'error' },
        });
      }
      let newAttendees = [...values.attendees];
      if (attendee.tempId) {
        newAttendees = newAttendees.filter(({ tempId, companionTempId }) => tempId !== attendee.tempId && companionTempId !== attendee.tempId);
      }

      if (attendee.id) {
        newAttendees = newAttendees.filter(({ id }) => id !== attendee.id);
      }

      form.change('attendees', newAttendees);

      if (values.seating.length) {
        for (const [table, seats] of Object.entries(values.seating)) {
          const indexInSeating = seats.indexOf(index);
          if (indexInSeating === -1) continue;
          delete values.seating[table][indexInSeating];
        }
      }
      // eslint-disable-next-line no-empty
    } catch (error) { console.error(error); }
  };

  const renderAttendees = (values) => {
    const attendees = values.attendees.map((attendee, index) => { attendee.index = index; return attendee; });

    if (participantCategoryGroupings) {
      return (
        <>
          {participantCategoryGroupings.map((grouping) => {
            const filteredAttendees = attendees.filter(({ ticketCategory, companionId }) => {
              if (!grouping.showCompanion && companionId) return false;
              if (grouping.showCompanion && companionId) return true;
              if (ticketCategory.id === grouping.ticketCategoryId) return true;
              if (grouping.additionalCategoryIds && grouping.additionalCategoryIds.includes(ticketCategory.id)) return true;
              return false;
            });

            if (!filteredAttendees.length && noAttendeeCountChange) return null;
            return (
              <>
                <Typography variant="h5" style={{ marginTop: '60px', marginBottom: '0px' }}>{grouping.label}</Typography>
                {grouping.description && !values.waitingList && (
                  <Alert severity="info" sx={{ mt: 3 }}>
                    <Typography>
                      <JsxParser
                        components={{ Typography, Stack }}
                        jsx={grouping.description}
                      />
                    </Typography>
                  </Alert>
                )}
                <ParticipantPromptGuest
                  attendees={filteredAttendees}
                  handleDelete={(attendeeIndex) => handleDelete(attendeeIndex, grouping, values)}
                  startParticipantNumber={0}
                  noEmail={noEmail}
                  showAddressFields={grouping.showAddressFields}
                  readOnlyAfterCreation={grouping.readOnlyAfterCreation}
                  attendeeLabel={grouping.attendeeLabel}
                  attendeeEmailLabel={grouping.attendeeEmailLabel}
                  noAttendeeCountChange={noAttendeeCountChange}
                />
                {!noAttendeeCountChange && !noAttendeeCreation && (
                  <Grid container direction="row" justifyContent="center" style={{ marginTop: '40px', marginBottom: '10px' }}>
                    <Grid item>
                      <Fab size="medium" variant="extended" color={attendeeLimitReached ? undefined : 'primary'} aria-label="add" onClick={() => handleAdd(grouping, values)}>
                        <AddIcon sx={{ mr: 1 }} />
                        {grouping.addAttendeeText ? grouping.addAttendeeText : 'Gast hinzufügen'}
                      </Fab>
                    </Grid>
                  </Grid>
                )}
              </>
            );
          })}
        </>
      );
    }
    return (
      <>
        <ParticipantPromptGuest attendees={attendees} handleDelete={handleDelete} startParticipantNumber={0} noEmail={noEmail} />
        {!noAttendeeCountChange && !noAttendeeCreation && (
          <Grid container direction="row" justifyContent="center" style={{ marginTop: '40px', marginBottom: '10px' }}>
            <Grid item>
              <Fab size="medium" variant="extended" color={attendeeLimitReached ? undefined : 'primary'} aria-label="add" onClick={() => handleAdd(null, values)}>
                <AddIcon sx={{ mr: 1 }} />
                Gast hinzufügen
              </Fab>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  return (
    <>
      {!noGuestPrefilling && (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" style={{ marginTop: '20px', marginBottom: '10px' }}>
              Gastgeber
              {values.waitingList ? ' (Warteliste)' : ''}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {mainAttendee.map((field) => (
                <InputField key={field.name} {...field} />
              ))}
            </Grid>
          </Grid>
          {!participantCategoryGroupings && (
            <Grid item xs={12} sx={{ marginTop: '30px' }}>
              <Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.5)' }} />
            </Grid>
          )}
        </Grid>
      )}
      {renderAttendees(values)}
    </>
  );
}
