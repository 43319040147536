/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import { Grid } from '@mui/material';

import InputField from '../../../Components/InputField';

export default function MainParticipantPrompt({ steps, params }) {
  const fields = useMemo(() => {
    let nameFields = [
      {
        name: 'title',
        label: 'Anrede',
        type: 'titleSelect',
        required: true,
        disabled: true,
        gridSize: { xs: 12, md: 3 },
      },
      {
        name: 'forename',
        label: 'Vorname',
        required: true,
        disabled: true,
        gridSize: { xs: 12, md: 4 },
      },
      {
        name: 'name',
        label: 'Nachname',
        required: true,
        disabled: true,
        gridSize: { xs: 12, md: 5 },
      },
    ];
    if (params?.useContactPerson) {
      nameFields = [
        {
          name: 'customAttributes.contactPersonTitle',
          label: 'Anrede',
          type: 'titleSelect',
          required: true,
          gridSize: { xs: 12, md: 3 },
        },
        {
          name: 'customAttributes.contactPersonForename',
          label: 'Vorname',
          required: true,
          gridSize: { xs: 12, md: 4 },
        },
        {
          name: 'customAttributes.contactPersonName',
          label: 'Nachname',
          required: true,
          gridSize: { xs: 12, md: 5 },
        },
      ];
    }

    const fields = [
      ...nameFields,
      {
        name: 'company',
        label: 'Firma',
        gridSize: { xs: 12 },
      },
      {
        name: 'customAttributes.street',
        label: 'Straße',
        required: true,
        gridSize: { xs: 12 },
      },
      {
        name: 'customAttributes.postalcode',
        label: 'PLZ',
        type: 'number',
        required: true,
        gridSize: { xs: 12, md: 3 },
      },
      {
        name: 'customAttributes.city',
        label: 'Ort',
        required: true,
        gridSize: { xs: 12, md: 9 },
      },
      {
        name: 'customAttributes.phone',
        label: 'Telefon',
        type: 'tel',
        required: true,
        gridSize: { xs: 12 },
      },
      {
        name: params?.useContactPerson ? 'customAttributes.contactPersonEmail' : 'email',
        label: params?.useContactPerson ? 'E-Mail' : 'E-Mail (an diese E-Mail wird die Einlasskarte gesendet)',
        type: 'email',
        required: true,
        gridSize: { xs: 12 },
      },
    ];

    if (!steps.find(({ type }) => type === 'participantPrompt')) {
      fields.push({
        name: 'customAttributes.vegetarian',
        label: 'Vegetarisches Essen',
        type: 'checkbox',
      });
    }

    if (params?.customAttributes) {
      for (const attr of params.customAttributes) {
        fields.push({
          name: `customAttributes.${attr.code}`,
          label: attr.name,
          placeholder: attr.placeholder,
          gridSize: { xs: 12 },
        });
      }
    }
    return fields;
  }, [params?.useContactPerson, params?.customAttributes, steps]);

  return (
    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
      {fields.map((field) => (
        <InputField key={field.name} {...field} />
      ))}
    </Grid>
  );
}
