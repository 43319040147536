const config = {
  heading: 'Ticketzuweisung Sportpresseball 5. November',
  progressHeading: 'Bitte fügen Sie Ihre Mitarbeiter hinzu',
  footerLinks: [
    {
      name: 'Impressum (Patronatsverein)',
      link: 'https://patronatsverein.de/impressum/',
    },
    {
      name: 'Impressum (Oper Frankfurt)',
      link: 'https://oper-frankfurt.de/de/impressum/',
    },
    {
      name: 'Datenschutz',
      type: 'privacy',
    },
    /* {
      name: 'AGB',
      link: 'https://wirkung.group/digital/haftungsausschluss/',
    }, */
    {
      name: 'Technik',
      link: 'https://wirkung.group/digital/',
    },
  ],

};

export default config;
