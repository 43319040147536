import React, { useContext, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { Container, Stack, styled } from '@mui/system';
import {
  CircularProgress, Grid, Paper, Typography,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { getInviteGql } from '../InviteResponse/gql';
import RsvpHeading from '../../Components/RsvpHeading';
import { GeneralContext } from '../../util/Store';

const PaperBox = styled(Paper)({
  padding: '36px 36px 36px 36px',
  paddingBottom: 10,
  position: 'relative',
});

const ErrorTypo = styled(Typography)({
  textAlign: 'center',
  marginTop: '35vh',
});

export default function Declined() {
  const { inviteCode } = useParams();
  const snackbar = useSnackbar();
  const generalStore = useContext(GeneralContext);
  const {
    data,
    loading,
  } = useQuery(getInviteGql, {
    fetchPolicy: 'no-cache',
    variables: {
      inviteCode,
    },
    onError: () => snackbar.showMessage('Fehler beim laden.'),
  });

  useEffect(() => {
    if (!data?.getInvite) return;
    generalStore.setContactInfo(data?.getInvite.inviteForm.frontendSettings.contactInfo);
  }, [data?.getInvite]);

  const invite = useMemo(() => data?.getInvite, [data?.getInvite]);

  if (!invite || loading) {
    return (
      <Stack alignItems="center" sx={{ mt: 5 }}>
        <CircularProgress />
      </Stack>
    );
  }

  if (!inviteCode) return (<ErrorTypo variant="h2">Fehlerhafter Link</ErrorTypo>);
  return (
    <Container maxWidth="lg">
      <RsvpHeading />
      <PaperBox sx={{ pb: 4, mb: 2 }} elevation={4}>
        <Grid container direction="row" justifyContent="center" sx={{ mb: 2 }}>
          <HighlightOffIcon color="error" sx={{ fontSize: 80 }} />
        </Grid>
        <Typography sx={{ textAlign: 'center', mb: 2 }} variant="h4">
          Ihre Absage wurde registriert.
        </Typography>
        <Typography sx={{ textAlign: 'center' }}>
          Wir freuen uns, wenn wir Sie im nächsten Jahr zur Operngala begrüßen dürfen.
        </Typography>
        <Typography sx={{ textAlign: 'center' }}>
          Wenn Sie künftig keine Einladung mehr erhalten möchten, senden Sie uns bitte eine kurze E-Mail.
        </Typography>
        {/* <Grid container direction="row" justifyContent="center" sx={{ pt: 4 }}>
          <Button
            variant="outlined"
            to={`/einladung/${inviteCode}/response`}
            component={Link}
          >
            Zurück
          </Button>
        </Grid> */}
      </PaperBox>
    </Container>
  );
}
