import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { RestLink } from 'apollo-link-rest';

// Set `RestLink` with your endpoint
const restLink = new RestLink({
  uri: '/v1/',
});

// Setup your client
const client = new ApolloClient({
  cache: new InMemoryCache(),
  // link: ApolloLink.from([authLink, restLink]),
  link: ApolloLink.from([restLink]),
});

export default client;
